import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import PageLayout from "../../components/Layout/PageLayout";
import PageBlock from "../../components/Layout/PageBlock";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Badge from "../../components/Badge";

import {
    BlockTitle,
    ApplyLink,
    staticImgContainer,
    navBarHeight,
    darkGreen,
    darkRed,
    primaryColour,
    CTALink as CTALinkToNext,
    PageBlockNextPageDivider,
} from "../../styles/component-stylings";

import { ArrowRight, ExternalLink } from "react-feather";

const CTALink = styled(ApplyLink)`
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    position: relative; // to bypass padding on header below
`;

const HighlightsBlockTitle = styled(BlockTitle)`
    // to account for navbar
    padding-top: ${navBarHeight};
    margin-top: -${navBarHeight};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`;

const HighlightsProjectTitle = styled.h2`
    color: black;
    font-weight: 600;
    font-size: 2rem;
    padding: 0;
    margin: 0;
    margin-right: 1rem;
`;

const PAGE_TITLE = "Highlights";

const HighlightsPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta
                    name='description'
                    content='A small overview of some of the work highlights for Digital Surveillance Collection from the Australian Federal Police'
                />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>

                <p>We are extremely proud of the work we do at DSC, applying both technical and innovative solutions to keep Australians safe.</p>
                <p>Here are some examples of the kind of things we work on.</p>

                <HighlightsBlockTitle>
                    <HighlightsProjectTitle>Operation Ironside</HighlightsProjectTitle>
                    <Badge backgroundColour={darkRed}>Operation</Badge>
                </HighlightsBlockTitle>

                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center center" }}
                    src='../../images/ironside.png'
                    layout='fixed'
                    placeholder='blurred'
                    alt='Operation Ironside'
                />
                <p>
                    Operation Ironside is a long-term, covert AFP-led investigation into transnational and serious organised crime. The AFP and FBI monitored
                    criminals' encrypted communications over a Dedicated Encrypted Communications Platform named AN0M, to traffic illicit drugs and weapons to
                    Australia, as well as order local executions.
                </p>
                <p>
                    It is the biggest crime operation in the AFP's 40 year history. DSC had a huge part to play in it, being instrumental in establishing,
                    driving and running the technical elements that contributed greatly to the success of Operation Ironside.
                </p>
                <CTALink href='https://online.afp.gov.au/ironside' target='_blank' rel='external'>
                    <span>Learn more</span>
                    <ExternalLink />
                </CTALink>
                <hr />

                <HighlightsBlockTitle id='hamilton'>
                    <HighlightsProjectTitle>Hamilton</HighlightsProjectTitle>
                    <Badge backgroundColour={primaryColour}>Analytics</Badge>
                </HighlightsBlockTitle>

                <StaticImage
                    style={{ height: "600px", width: "100%" }}
                    imgStyle={{ objectPosition: "center bottom" }}
                    src='../../images/hamilton-analytics.jpg'
                    layout='fixed'
                    placeholder='blurred'
                    alt='Hamilton Data Graph Nodes'
                />
                <p>Our data analytics team compiles, analyses and assesses intelligence to help with AFP operations.</p>
                <p>Lately, we've been utilising a bespoke software analysis solution called Hamilton.</p>
                <p>It was and is used extensively in Operation Ironside to create a communication network map between hundreds of AN0M devices.</p>
                <p>
                    Devices are rolled up into "Communities" and given a "Community ID", which was derived using the Louvain community detection algorithm.
                    Based on the actual frequency of communication between devices - if devices chatted a lot, then they're probably in the same "community".
                </p>
                <p>In this image, large communities are denoted in red, while smaller ones (and single devices) are in blue.</p>
                <hr />

                <HighlightsBlockTitle id='wifi-charger'>
                    <HighlightsProjectTitle>WiFi Battery Charger and PSU </HighlightsProjectTitle>
                    <Badge backgroundColour={darkGreen}>Hardware</Badge>
                </HighlightsBlockTitle>

                <StaticImage
                    style={staticImgContainer}
                    imgStyle={{ objectPosition: "center bottom" }}
                    src='../../images/device-2.jpg'
                    layout='fixed'
                    placeholder='blurred'
                    alt='Wifi Battery Charger and Power Supply 2'
                />
                <p>Our hardware engineering design, develop, build, and use custom electronics to assist with AFP technical operations.</p>
                <p>Recently, we've been working on a WiFi-controlled battery charger and power supply unit (PSU).</p>
                <p>
                    This electronics assembly was designed in-house, and is used to provide reliable power for our electronics from host devices. It can be
                    monitored and controlled wirelessly, and provides for customisable low voltage cut-outs to assist in preserving the life of rechargeable
                    batteries.
                </p>

                <PageBlockNextPageDivider>
                    <CTALinkToNext $invert $borderColour={primaryColour} to='/careers' rel='next'>
                        <span>Careers</span>
                        <ArrowRight />
                    </CTALinkToNext>
                </PageBlockNextPageDivider>
            </PageBlock>
        </PageLayout>
    );
};

export default HighlightsPage;
