import * as React from "react";
import styled from "styled-components";
import { primaryColour } from "../styles/component-stylings";

const Badge = ({ children, backgroundColour, textColour, marginLeft }) => {
    const BadgeContainer = styled.span`
        padding: 0.25rem 0.5rem;
        margin: 0.5rem 0;
        ${marginLeft && "margin-left: 1rem;"}
        background-color: ${backgroundColour || primaryColour};
        color: ${textColour || "white"};
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 500;
        width: fit-content;
    `;

    return <BadgeContainer>{children}</BadgeContainer>;
};

export default Badge;
